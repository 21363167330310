import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { toast } from "react-hot-toast";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  inputVisible:boolean;
  submitAllData:any;
  submitData: {},
  userToken: string,
  profile_picture:any,
  resume:any,
  identification_card:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LecturerSignUpController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  LecturerSignUpApiCallId: string = "";
  getOtpApiCallId: string = "";
  getOtpVerifyCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      inputVisible:false,
      submitAllData:{},
      submitData: {},
      userToken: "",
      profile_picture:'',
      resume:'',
      identification_card:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.apiCallResponce(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  apiCallResponce = (apiRequestCallId:any, responseJson:any) => { 
    apiRequestCallId === this.LecturerSignUpApiCallId && this.handleSignupApiResponse1(responseJson);
    apiRequestCallId === this.getOtpApiCallId && this.handleOtpResponse1(responseJson);
  }

  handleSignupApiResponse1(responseJson:any) {
    this.getNumberOtp1(`${responseJson.data.attributes.full_phone_number}`)
    if(!responseJson.errors){
      this.props.history.push({
        pathname: '/SignupVerifuNumber',
        state: { data: this.state.submitAllData.phonenumber }
    })
    setStorageData("userId", responseJson?.data?.id)
    localStorage.setItem("email", responseJson?.data?.attributes?.email)
    }
    else if (responseJson.errors.length){
      const [{email=null, password=null}={}] = responseJson.errors
      if(email){
        toast.error(email + '.');
      }
      else if(password){
        toast.error(password + '.');
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    if(window.location.pathname == "/SignupVerifuNumber"){
      let phoneAFriend = await getStorageData("contact_no")
       this.getNumberOtp1(`${phoneAFriend}`)
     }
  }

  getNumberOtp1 =  (value: string) => {
    const header = {
      "Content-Type": "application/json"
    }; 
    
    const DATA = {
      data:{
        attributes:{
          full_phone_number: value
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.getOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(DATA)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/send_otps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOtpResponse1(responseJson:{ errors:{ full_phone_number: string } , data: {
    attributes: any; id:string, type: string
}, meta: { token : string}}) {
    if (!responseJson.errors) {
      this.setState({
        userToken: responseJson?.meta.token
     })
     setStorageData("lectureToken", responseJson?.meta.token)
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleFileChangeCardId = (event: any) => {
    const Filename = event?.target?.files[0]; 
    this.setState({ identification_card: Filename });
  };

  handleFileResume = (event: any) => {
    const Filename = event?.target?.files[0]; 
    this.setState({ resume: Filename });
  };

  handleFileProfilePhoto = (event: any) => {
    const Filename = event?.target?.files[0]; 
    this.setState({ profile_picture: Filename });
  };


  onClickSubmit = (values: any) => {
    this.setState({submitAllData:values})
    const header = {
    }; 
    
    const DATA = {
      data:{
        attributes:{
            email: values?.officialemail,
            password: values?.password,
            password_confirmation: values?.confirmpassword
        }
      }
    }


    let countryCodeNum: string;

    if (values?.countryNumber?.includes("+")) {
      countryCodeNum = values?.countryNumber;
    } else {
      countryCodeNum = `+${values?.countryNumber}`;
    }

    setStorageData("contact_no" , countryCodeNum + values?.phonenumber)
    const data = {
      data : {
        type: "email_account",
      attributes: {
          first_name: values?.firstname,
          email: values?.officialemail,
          password: values?.password,
          password_confirmation: values?.confirmpassword,
          role_id: 2,
          department: values?.department,          
          last_name: values?.lastname,
          qualification: values?.qualification,
          full_phone_number: countryCodeNum + values?.phonenumber,
          date_of_birth: values?.dateofbirth,
          major: values?.major,
          experience: values?.experience,
          biography: values?.biography,        
      }
      }
  };

    const formData = new FormData();
    formData.append('data[type]', 'email_account');
    formData.append('data[attributes][first_name]', values?.firstname);
    formData.append('data[attributes][email]', values?.officialemail);
    formData.append('data[attributes][password]', values?.password);
    formData.append('data[attributes][password_confirmation]', values?.confirmpassword);
    formData.append('data[attributes][role_id]', '2');
    formData.append('data[attributes][department]', values?.department);
    formData.append('data[attributes][last_name]', values?.lastname);
    formData.append('data[attributes][qualification]', values?.qualification);
    formData.append('data[attributes][full_phone_number]', countryCodeNum + values?.phonenumber);
    formData.append('data[attributes][date_of_birth]', values?.dateofbirth);
    formData.append('data[attributes][major]', values?.major);
    formData.append('data[attributes][experience]', values?.experience);
    formData.append('data[attributes][biography]', values?.biography);
    formData.append('data[attributes][identification_card]', this.state.identification_card);
    formData.append('data[attributes][resume]', this.state.resume);
    formData.append('data[attributes][profile_picture]', this.state.profile_picture);
    formData.append('identification_card', this.state.identification_card);
    formData.append('resume', this.state.resume);
    formData.append('profile_picture', this.state.profile_picture);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.LecturerSignUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
