import React from "react";

// Customizable Area Start
import { Box, Paper } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "matchmedia-polyfill";
import "matchmedia-polyfill/matchMedia.addListener";
import Slider from "react-slick";
import "../assets/css/MyCourses.css";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

let settings = {
  dots: false,
  speed: 1200,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 1,
  adaptiveHeight: true,
  autoplay: false,
  nav: true,
  autoplaySpeed: 2000,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1499,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

let setting_1 = {
  dots: false,
  speed: 1200,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: false,
  nav: true,
  autoplaySpeed: 2000,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

import MyCoursesController, { Props } from "./MyCoursesController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class MyCourse extends MyCoursesController {
  constructor(props: Props) {
    super(props);
  }

  renderCourseTab = () => {
    return <ul>
    <li
      className={
        this.state.toggleTabpan === "ongoing" ? `current` : ""
      }
    >
      <span>
        <button
          type="button"
          data-test-id="setOngoing"
          onClick={() =>
            this.setState({ toggleTabpan: "ongoing" })
          }
        >
          Ongoing
        </button>
      </span>
    </li>
    <li
      className={
        this.state.toggleTabpan === "completed"
          ? `current`
          : ""
      }
    >
      <span>
        <button
          type="button"
          data-test-id="setCompleted"
          onClick={() =>
            this.setState({ toggleTabpan: "completed" })
          }
        >
          Completed
        </button>
      </span>
    </li>
  </ul>
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="My courses"
        >
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <div className="content-wrapper">
              <div className="home-search-block">
                <form>
                  <input
                    data-test-id="inputSerch"
                    type="search"
                    placeholder="Search here"
                    onChange={this.handleSearchChange}
                  />
                </form>
              </div>
              <div className="item-wrapper">
                <div className="inner-heading">
                  <h6>My Course</h6>
                </div>
                <div className="course-tab">
                  <div className="course-tab-nav">
                    {this.renderCourseTab()}
                  </div>
                  {this.state.toggleTabpan === "ongoing" &&
                  this.state.filteredIncompleteCourses.length === 0 ? (
                    <Box textAlign="center">No Courses Available</Box>
                  ) : null}
                  {this.state.toggleTabpan === "completed" &&
                  this.state.filteredCompletedCourses.length === 0 ? (
                    <Box textAlign="center">No Courses Available</Box>
                  ) : null}

                  <div className="course-tab-link">
                    {this.state.toggleTabpan === "ongoing" && (
                      <div className="course_slider">
                        <Slider
                          {...settings}
                          infinite={
                            this.state.filteredIncompleteCourses.length >= 4
                          }
                        >
                          {this.state.filteredIncompleteCourses.map(
                            (item, index) => {
                              return (
                                <div
                                  key={item.id}
                                  onClick={() => this.onClickRedirct(item.id)}
                                  data-test-id="checkid"
                                  className="course-tab-block"
                                >
                                  {item.courseImage ? (
                                    <img
                                      key={index}
                                      src={baseURL + item.courseImage}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "120px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        borderBottom: "0.1px solid #94A3B8",
                                      }}
                                    >
                                      No image available
                                    </div>
                                  )}
                                  <div className="tab-process-block">
                                    <span>{item.totalPercentage}%</span>
                                    <span>
                                      {item.numberOfLessComple}/
                                      {item.numberOfLessons} Sessions
                                    </span>
                                  </div>
                                  <div className="tab-process-block">
                                    <p />
                                  </div>
                                  <h6>{item.courseName}</h6>
                                  <small>A Course by {item.lecturer}.</small>
                                  <p>{this.categoriesName(item.categoryId)}</p>
                                </div>
                              );
                            }
                          )}
                        </Slider>
                      </div>
                    )}
                    {this.state.toggleTabpan === "completed" && (
                      <div className="course_slider">
                        <Slider
                          {...settings}
                          infinite={
                            this.state.filteredIncompleteCourses.length >= 4
                          }
                        >
                          {this.state.filteredCompletedCourses.map(
                            (elem, index) => {
                              return (
                                <div
                                  key={elem.id}
                                  onClick={() => this.onClickRedirct(elem.id)}
                                  data-test-id="checkid"
                                  className="course-tab-block"
                                >
                                  {elem.courseImage ? (
                                    <img
                                      key={index}
                                      src={baseURL + elem.courseImage}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "120px",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        borderBottom: "0.1px solid #94A3B8",
                                      }}
                                    >
                                      No image available
                                    </div>
                                  )}

                                  <div className="tab-process-block">
                                    <span>{elem.totalPercentage}%</span>

                                    <span>
                                      {elem.numberOfLessComple}/
                                      {elem.numberOfLessons} Sessions
                                    </span>
                                  </div>
                                  <div className="tab-process-block">
                                    <p />
                                  </div>
                                  <h6>{elem.courseName}</h6>

                                  <small>A Course by {elem.lecturer}.</small>
                                  <p>{this.categoriesName(elem.categoryId)}</p>
                                </div>
                              );
                            }
                          )}
                        </Slider>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="item-wrapper">
                <div className="inner-heading">
                  <h6>Upcoming Sessions</h6>
                </div>
                {this.state.validLiveSessions.length === 0 ? (
                  <Box textAlign="center">No Upcoming Sessions</Box>
                ) : null}

                <div className="upcoming-wrapper">
                  <Slider
                    {...setting_1}
                    infinite={this.state.validLiveSessions.length >= 4}
                  >
                    {this.state.validLiveSessions.map(
                      (elel: {
                        attributes: {
                          course: {
                            description: string;
                            course_name: string;
                            lecturer: string;
                            category_id: number;
                          };
                          account: {
                            first_name: string;
                            last_name: string;
                          };
                          join_url: string;
                          time_from: string;
                          time_to: string;
                          date: string;
                          thumbnail: string;
                          subject:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                        };
                      }) => {
                        return (
                          <div className="upcoming-block" data-test-id="testid">
                            <div className="block_module" id="checkidhaoi">
                              <div
                                className="inner-upcoming checkingin"
                                id="checkid"
                              >
                                <div className="upcoming-image classtest">
                                  {elel.attributes.thumbnail ? (
                                    <img
                                      src={baseURL + elel.attributes.thumbnail}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        height: "113px",
                                        textAlign: "center",
                                        borderBottom: "0.1px solid #94A3B8",
                                        marginBottom: "16px"
                                      }}
                                    >
                                      No image available
                                    </div>
                                  )}
                                </div>
                                <div className="upcoming-content">
                                  <h6 data-test-id="hellotest">
                                    {elel.attributes.course.course_name}
                                  </h6>
                                  <small>
                                    A Course by{" "}
                                    {elel.attributes.account.first_name}{" "}
                                    {elel.attributes.account.last_name}.
                                  </small>
                                  <p>
                                    {this.categoriesName(
                                      elel.attributes.course.category_id
                                    )}
                                  </p>
                                  <div className="upcoming-time" id="heytest">
                                    <span className="testclasshere">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M5.97472 3.23397L3.41639 5.3673C3.06639 5.65897 2.54139 5.6173 2.24972 5.25897C1.94972 4.90897 1.99972 4.38397 2.34972 4.08397L4.91639 1.95064C5.26639 1.65897 5.79139 1.70064 6.08305 2.05897C6.38305 2.40897 6.33305 2.93397 5.97472 3.23397ZM17.6497 4.08397L15.0831 1.95064C14.7331 1.65897 14.2081 1.70064 13.9081 2.05897C13.6164 2.40897 13.6664 2.93397 14.0164 3.23397L16.5747 5.3673C16.9247 5.65897 17.4497 5.6173 17.7497 5.25897C18.0497 4.90897 17.9997 4.38397 17.6497 4.08397ZM13.2247 12.6173L10.4164 10.9506V7.17564C10.4164 6.8423 10.1497 6.57564 9.81639 6.57564H9.76639C9.43305 6.57564 9.16639 6.8423 9.16639 7.17564V11.109C9.16639 11.4006 9.31639 11.6756 9.57472 11.8256L12.6164 13.6506C12.8997 13.8173 13.2664 13.734 13.4331 13.4506C13.6081 13.159 13.5164 12.784 13.2247 12.6173ZM9.99972 3.2423C5.85805 3.2423 2.49972 6.60064 2.49972 10.7423C2.49972 14.884 5.85805 18.2423 9.99972 18.2423C14.1414 18.2423 17.4997 14.884 17.4997 10.7423C17.4997 6.60064 14.1414 3.2423 9.99972 3.2423ZM4.16639 10.7423C4.16639 13.959 6.78305 16.5756 9.99972 16.5756C13.2164 16.5756 15.8331 13.959 15.8331 10.7423C15.8331 7.52564 13.2164 4.90897 9.99972 4.90897C6.78305 4.90897 4.16639 7.52564 4.16639 10.7423Z"
                                          fill="#0F172A"
                                        />
                                      </svg>
                                      {this.formatDate(elel.attributes.date)}
                                    </span>
                                    <span id="secondtest">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M15.3026 3.18166H16.0602C16.8935 3.18166 17.5753 3.86347 17.5753 4.69681V16.818C17.5753 17.6514 16.8935 18.3332 16.0602 18.3332H3.93898C3.10565 18.3332 2.42383 17.6514 2.42383 16.818V4.69681C2.42383 3.86347 3.10565 3.18166 3.93898 3.18166H4.69656V2.42408C4.69656 2.00741 5.03746 1.6665 5.45413 1.6665C5.8708 1.6665 6.21171 2.00741 6.21171 2.42408V3.18166H13.7875V2.42408C13.7875 2.00741 14.1284 1.6665 14.545 1.6665C14.9617 1.6665 15.3026 2.00741 15.3026 2.42408V3.18166ZM4.69656 16.818H15.3026C15.7193 16.818 16.0602 16.4771 16.0602 16.0604V6.96953H3.93898V16.0604C3.93898 16.4771 4.27989 16.818 4.69656 16.818Z"
                                          fill="#0F172A"
                                        />
                                      </svg>
                                      {elel.attributes.time_from} to{" "}
                                      {elel.attributes.time_to}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="upcoming-button"
                                data-test-id="datatestid"
                              >
                                <button className="button-gray" id="checkidone">
                                  Live{" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={25}
                                    height={24}
                                    viewBox="0 0 25 24"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M21.5 3H3.5C2.4 3 1.5 3.9 1.5 5V17C1.5 18.1 2.4 19 3.5 19H8.5V20C8.5 20.55 8.95 21 9.5 21H15.5C16.05 21 16.5 20.55 16.5 20V19H21.5C22.6 19 23.49 18.1 23.49 17L23.5 5C23.5 3.89 22.6 3 21.5 3ZM20.5 17H4.5C3.95 17 3.5 16.55 3.5 16V6C3.5 5.45 3.95 5 4.5 5H20.5C21.05 5 21.5 5.45 21.5 6V16C21.5 16.55 21.05 17 20.5 17ZM11 14.15L14.98 11.87C15.65 11.48 15.65 10.52 14.98 10.13L11 7.85C10.33 7.47 9.5 7.95 9.5 8.72V13.28C9.5 14.04 10.33 14.53 11 14.15Z"
                                      fill="#37657F"
                                    />
                                  </svg>
                                </button>
                                {this.isUpcomingSession(
                                  elel.attributes.date
                                ) ? (
                                  <button
                                    className="btn button-green"
                                    style={{
                                      border: "1px solid #8f9194",
                                      background: "#F1F5F9",
                                      color: "#8f9194",
                                    }}
                                    disabled={true}
                                    data-test-id="btn button-upcoming"
                                  >
                                    Upcoming
                                  </button>
                                ) : (
                                  <button
                                    className="btn button-green"
                                    data-test-id="btn button-green"
                                    onClick={() =>
                                      this.goToZoomFunct(
                                        elel.attributes.join_url
                                      )
                                    }
                                  >
                                    Join Now
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
    );
  }
}
